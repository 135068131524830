import axios from "axios";

const apiClient = axios.create({
    baseUrl: baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});

export default {
    addCartItem(id, count) {
        return apiClient.post(
            '/cart/add-to-cart',
            {id: id, count: count, json: 1}
        );
    },

    updateCartItem(id, count) {
        return apiClient.post(
            '/cart/update/',
            {id: id, count: count}
        );
    },
    removeCartItem(id) {
        return apiClient.post(
            '/cart/remove/',
            {id: id}
        );
    },
    getCartData(payload) {
        return apiClient.post(
            '/cart/data',
            payload
        );
    },
    clearCart() {
        return apiClient.post(
            '/cart/clear'
        );
    },
    getProductSearchResult(payload) {
        return apiClient.post('/search?autocomplete=1&term=' + payload.term)
    },
    getProductAcResult(payload) {
        return apiClient.post('/search?autocomplete=1&fo=1&term=' + payload.term)
    },
    getDeliveryAddressListForCurrentUser() {
        return apiClient.get('/account/delivery-address-list');
    },
    setCurrentDeliveryAddress(id) {
        return apiClient.get('/account/delivery-address-set?id=' + id);
    },
    editDeliveryAddress(payload) {
        return apiClient.post(
            '/account/delivery-address-edit',
            {'delivery_address_edit_form': payload}
        )
    },
    deleteDeliveryAddress(id) {
        return apiClient.post(
            '/account/delivery-address-delete',
            {id: id}
        )
    },
    getCountryCodeList(locale = '') {
        return apiClient.get(
            '/account/get-country-code-list?l=' + locale
        )
    },
    uploadCsvFile(formData) {
        return apiClient.post(
            '/cart/upload-order',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        )
    }
}