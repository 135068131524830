<template>
  <div>
    <transition-group
        tag="div"
        name="cardlist"
        class="uk-grid-medium uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid-match"
        uk-grid
        appear
    >
      <div
          v-for="item in deliveryAddressList"
          :key="item.Id"
      >
        <div
            class="uk-card uk-card-default uk-flex uk-flex-column ws-passive-card"
            :class="{ 'ws-active-card': isCurrentAddress(item.Id)}"
        >
          <div class="uk-card-body">
            <p
                class="uk-text-bold"
                v-if="item.Company"
            >{{ item.Company }}</p>
            <p class="uk-text-bold">{{ item.Firstname }} {{ item.Lastname }}</p>
            <p>{{ item.Street }} {{ item.Housenumber }}<br>
              {{ item.Zip }} {{ item.City }} ({{ item.CountryCode }})</p>
            <p v-if="item.PhoneNumber">{{ item.PhoneNumber }}</p>
          </div>
          <div class="uk-card-footer uk-margin-auto-top">
            <div class="uk-grid-small" uk-grid>
              <div class="uk-width-auto">
                <div
                    class="uk-text-large ws-app-link"
                    @click="showEditForm(item.Id)"
                    uk-tooltip="Adresse bearbeiten"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </div>
              </div>
              <div class="uk-width-auto">
                <div
                    v-if="!isCurrentAddress(item.Id)"
                    class="uk-text-large ws-app-link ws-app-link-danger"
                    @click="showConfirmDelete(item.Id)"
                    uk-tooltip="title:Löschen;pos:top-right"
                >
                  <i class="fa-regular fa-trash-can-xmark"></i>
                </div>
              </div>
              <div class="uk-width-expand uk-text-right">
                <div
                    v-if="!isCurrentAddress(item.Id) && !awaiting"
                    class="ws-ok-button uk-text-large"

                >
                  <i
                      class="fa-regular fa-location-dot ws-app-link"
                      @click="setDefaultAddress(item.Id)"
                      uk-tooltip="title:Als Standart setzen;pos:top-right"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
            class="uk-card uk-card-secondary"
        >
          <div
              class="uk-card-body uk-flex uk-flex-center uk-flex-middle ws-text-xlarge uk-height-1-1"
          >
            <span
                class="ws-app-link"
                @click="showAddForm"
            >
            <i class="fa-regular fa-circle-plus"></i>
              </span>
          </div>
        </div>
      </div>
    </transition-group>

    <div id="deliveryAddressFormContainer" uk-modal>
      <div class="ws-container-medium">
        <DeliveryAddressForm :list-store="DeliveryAddressStore" ref="deliveryAddressForm"></DeliveryAddressForm>
      </div>
    </div>
    <div id="confirmDeleteDeliveryAddress" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">{{$t('form.deliveryaddress.delete.headline')}}</h2>
        </div>
        <div class="uk-modal-body">
          <p>{{$t('form.deliveryaddress.delete.security-question')}}</p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button
              class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
          >{{ $t('general.cancel') }}
          </button>
          <button
              class="uk-button uk-button-danger"
              type="button"
              @click="deleteSelectedItem"
          >{{ $t('general.button-label-delete') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useDeliveryAddressStore} from "../store/DeliveryAddressStore.js";
import DeliveryAddressForm from "./DeliveryAddressForm.vue";

export default {
  name: "DeliveryAddressManagement",
  components: {DeliveryAddressForm},
  data() {
    return {
      awaiting: false,
      deliveryAddressList: [],
      selectedId: 0
    }
  },
  setup() {
    const DeliveryAddressStore = useDeliveryAddressStore()

    return {
      DeliveryAddressStore
    }
  },
  created() {
    this.DeliveryAddressStore.loadDeliveryAddressList().then((response) => {
      this.deliveryAddressList = this.DeliveryAddressStore.getItems
    })

  },
  methods: {
    setDefaultAddress(id) {
      this.awaiting = true
      this.DeliveryAddressStore.setCurrentDeliveryAddress(id).then((result) => {
        this.awaiting = false
      })
    },
    isCurrentAddress(id) {
      return this.DeliveryAddressStore.getCurrentDeliveryAddressId === id
    },
    showAddForm() {
      let countryCodeList = this.DeliveryAddressStore.getCountryCodeList
      console.log('preaddCC', countryCodeList)
      this.$refs.deliveryAddressForm.prefillForm({CountryCode: 'DE'}, countryCodeList)
      UIkit.modal("#deliveryAddressFormContainer").show();
    },
    showEditForm(id) {
      let address = this.DeliveryAddressStore.getItem(id)
      let countryCodeList = this.DeliveryAddressStore.getCountryCodeList
      console.log('CC', countryCodeList)
      this.$refs.deliveryAddressForm.prefillForm(address, countryCodeList)
      UIkit.modal("#deliveryAddressFormContainer").show();
    },
    deleteSelectedItem() {
      if (this.selectedId) {
        this.DeliveryAddressStore.deleteDeliveryAddress(this.selectedId).then((result) => {
          UIkit.modal("#confirmDeleteDeliveryAddress").hide()
        })
      }
    },
    showConfirmDelete(id) {
      this.selectedId = id
      UIkit.modal("#confirmDeleteDeliveryAddress").show()
    }
  },
  computed: {
    storeListState() {
      return this.DeliveryAddressStore.getItems
    }
  },
  watch: {
    storeListState(newVal, oldVal) {
      this.deliveryAddressList = newVal
    }
  }
}
</script>
<style>
.cardlist-enter {
  transform: translateY(-10px);
}

.cardlist-enter-from {
  transform: translateY(10px);
}

.cardlist-enter-active, .cardlist-move {
  transition: transform 0.5s ease;
}
</style>