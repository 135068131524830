<template>
  <div>
    <div class="uk-grid uk-grid-small" uk-grid>
      <div class="uk-width-1-1 uk-width-auto@m uk-width-1-1@l uk-width-auto@xl">
        <div
            class="js-upload uk-placeholder uk-position-relative"
            ref="uploadForm"
            @dragover.prevent
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @drop="handleFileUpload"
            :class="{ 'ws-drag-over' : isDraggingOver }"
        >
          <i class="ws-icon-medium ws-placolder-icon fa-regular fa-cart-circle-arrow-up"></i>
          <span class="uk-margin-medium-left">{{ $t('form.upload.placeholder.drop-file-here') }}</span>
          <div uk-form-custom>
            <input
                type="file"
                id="csv-order-upload"
                class="uk-input"
                accept=".csv"
                @change="handleFileUpload"
            >
            <span class="uk-link uk-margin-small-left">{{ $t('form.upload.placeholder.select-file-here') }}</span>
          </div>
          <div
              class="ws-placeholder-info-icon"
              uk-toggle="target: #cart-upload-info"
          ><i class="ws-icon-medium fa-solid fa-square-info"></i>
          </div>
          <div
              id="cart-upload-info"
              uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <h2 class="uk-modal-title">{{ $t('form.upload.info.csv-format-header')}}</h2>
              <span v-html="$t('form.upload.info.csv-format-content')"></span>
            </div>
          </div>
        </div>


      </div>
      <div class="uk-width-1-1 uk-width-expand@m uk-flex uk-flex-right@m uk-flex-left@l uk-flex-right@xl">
        <a
            class="uk-button uk-button-primary ws-icon-button uk-margin-medium-right"
            href="/cart/download"
            target="_blank"
            :uk-tooltip="$t('text.cart-download-tooltip')"
        >
          <i class="fa-regular fa-cart-circle-arrow-down"></i>
        </a>
          <div
              class="uk-button ws-delete-button ws-icon-button"
              :uk-tooltip="$t('text.cart-clear-tooltip')"
              @click="showConfirmClear"
              >
            <i class="fa-regular fa-cart-circle-xmark"></i>
          </div>

      </div>
    </div>
    <div
        class="uk-margin-small-top"
        v-if="uploadMessage.length > 0"
    >
      <div
          class="uk-width-1-1 uk-width-expand@m uk-alert-danger"
          uk-alert
      >
        {{ uploadMessage }}
      </div>
    </div>
    <div id="confirmClearCart" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">{{$t('form.cart-clear.headline')}}</h2>
        </div>
        <div class="uk-modal-body">
          <p>{{$t('form.cart-clear.security-question')}}</p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button
              class="uk-button uk-button-default uk-modal-close uk-margin-small-right"
              type="button"
          >{{ $t('general.cancel') }}
          </button>
          <button
              class="uk-button uk-button-danger"
              type="button"
              @click="clearCart"
          >{{ $t('general.button-label-clear-cart') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";
import UIkit from "uikit";

export default {
  name: "CsvOrderUpload",
  data() {
    return {
      isDraggingOver: false,
      dragCounter: 0,
      uploadFilename: '',
      uploadMessage: ''
    }
  },
  methods: {
    dragEnter(e) {
      this.dragCounter++;
      this.isDraggingOver = true;
    },
    dragLeave() {
      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.isDraggingOver = false;
      }
    },
    showConfirmClear(id) {
      UIkit.modal("#confirmClearCart").show()
    },
    clearCart() {
      ApiService.clearCart().then((result) => {
        location.reload()
      })
    },
    async handleFileUpload(event) {
      event.preventDefault()
      this.isDraggingOver = false;
      let uploadedFile = null
      if (event.target.files) {
        uploadedFile = event.target.files[0];
      } else {
        if (event.dataTransfer) {
          uploadedFile = event.dataTransfer.files[0]
        }
      }

      if (uploadedFile) {
        if (uploadedFile.size > 100000) {
          this.uploadMessage = 'general.upload-to-big'
        }
        this.uploadFilename = uploadedFile.name
        let formData = new FormData()
        formData.append('csvOrderFile', uploadedFile)

        ApiService.uploadCsvFile(formData).then((result) => {
          if (result.data.success) {
            location.reload();
          } else {
            this.uploadMessage = result.data.message
          }
        })
      }
    }
  }
}
</script>


